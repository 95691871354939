<template>
  <s-radio-group
    v-model="value"
    :class="props.class"
    :name="fieldName"
    :isDisabled="props.disabled"
    @update:modelValue="handleModelUpdate"
  >
    <template v-for="(opt, index) in options">
      <slot
        v-if="hasSlot(`option-${index}`)"
        :id="name + '_' + opt.value"
        :name="`option-${index}`"
        :label="opt.label"
        :value="opt.value"
        :index="index"
        :handleModelUpdate="handleModelUpdate"
        :data="opt.data"
      ></slot>
      <slot
        v-else-if="hasSlot(`default`)"
        :id="name + '_' + opt.value"
        :name="'default'"
        :label="opt.label"
        :value="opt.value"
        :index="index"
        :handleModelUpdate="handleModelUpdate"
        :data="opt.data"
      ></slot>
      <s-radio
        v-else
        :id="name + '_' + opt.value"
        :key="index"
        :value="opt.value"
        v-bind="optionProps"
        :isDisabled="opt.isDisabled"
        @click="emit('click')"
      >
        <template v-if="!opt.tooltip">
          {{ $t(opt.label) }}
        </template>
        <span v-else class="flex gap-4 items-center">
          {{ $t(opt.label) }}
          <s-tooltip
            arrow
            :content="$t(opt.tooltip)"
            :duration="0"
            :distance="4"
            useFlip
            flipOnUpdate
            placement="top"
            trigger="mouseenter focus"
            :theme="'studio-tooltip'"
            :zIndex="600"
          >
            <template #target>
              <s-icon icon="ic-v2-state-info-circle-line" size="xl" class="text-placeholder flex" />
            </template>
          </s-tooltip>
        </span>
      </s-radio>
    </template>
  </s-radio-group>

  <st-error-message :name="fieldName" :showError="showFieldError" />
</template>

<script setup lang="ts">
import { computed, ref, toRefs, useSlots, watch } from 'vue';

import StErrorMessage from '@/components/validation/st-error-message.vue';
import useValidation from '@/composables/useValidation';
import type { RadioGroupProps } from '@/types/common/form.type';

const slots = useSlots();

const props = defineProps<RadioGroupProps>();
const { rules } = toRefs(props);
const showFieldError = ref(false);

const hasSlot = (name: string) => {
  return !!slots[name];
};

const emit = defineEmits<{
  'update:modelValue': [v: string];
  change: [v: string];
  click: [v: void];
}>();

const value = ref(props.modelValue);

const setFieldValue = ref<(value: string) => void>();

const fieldName = computed<string>(() => props.name ?? '');

if (fieldName.value) {
  const { setValue, showError } = useValidation<string>({
    fieldName: fieldName.value,
    rules,
    value
  });

  setFieldValue.value = setValue;

  watch(
    () => showError.value,
    (v: boolean) => {
      showFieldError.value = v;
    }
  );
}

watch(
  () => props.modelValue,
  (v?: string) => {
    value.value = v;
  }
);

const handleModelUpdate = (e: string) => {
  if (setFieldValue.value) {
    setFieldValue.value(e);
  }
  emit('update:modelValue', e);
};
</script>
